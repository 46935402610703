/* eslint-disable import/first */
typeof window !== 'undefined' && require('intersection-observer');

import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { LazyImage } from 'react-lazy-images';

import Layout from '../components/Layout';
import Breaker from '../components/Breaker';
import SEO from '../components/seo';
import { Title } from '../styles/base';
import Loader from '../images/Loader.svg';
import { PortfolioItem } from '../styles/portfolios';
import { AlbumTitle } from '../styles/gallery';
import { PORTFOLIO_PATH } from '../constants/Routes';
import { KEYWORDS } from '../constants/Keywords';
import portfolioData from '../data/portfolio.json';

const Portfolio = () => (
  <Layout>
    <SEO title="Portfolio" keywords={[...KEYWORDS]} />

    <Title>Portfolio</Title>

    <Breaker />

    <Grid>
      <Row>
        {portfolioData.map(({ title, slug, cover }) => {
          return (
            <Col md={4} key={slug}>
              <PortfolioItem to={`${PORTFOLIO_PATH}/${slug}`}>
                <LazyImage
                  src={cover}
                  alt={title}
                  placeholder={({ imageProps, ref }) => (
                    <div style={{ padding: '80px 0' }} ref={ref}>
                      <Loader />
                    </div>
                  )}
                  actual={({ imageProps }) => <img {...imageProps} alt={imageProps.alt} />}
                />

                <AlbumTitle>
                  {title}
                </AlbumTitle>
              </PortfolioItem>
            </Col>
          );
        })}
      </Row>
    </Grid>
  </Layout>
);

export default Portfolio;
